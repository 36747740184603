@import "styles/share";

.dolce-loading {
  &--center {
    .dolce-loading__spinner {
      left: 50%;
      top: 50%;
      right: auto !important;
      bottom: auto !important;
      margin: 0 !important;
      transform: translate(-50%, -50%);
    }
  }
  &__spinner {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 15%;
    bottom: 13px;
    margin-top: -1.5rem;
    margin-left: -1.5rem;

  }

  &__simple {
    display: block;
    font-size: 10px;
    position: relative;
    text-indent: 100px;
    overflow: hidden;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    border-top: 0.4rem solid rgba(205, 24, 31, 0.2);
    border-right: 0.4rem solid rgba(205, 24, 31, 0.2);
    border-bottom: 0.4rem solid rgba(205, 24, 31, 0.2);
    border-left: 0.4rem solid #cd181f;
    &.is-gray {
      border-top: 0.3rem solid rgba(255, 255, 255, 0.75);
      border-right: 0.3rem solid rgba(255, 255, 255, 0.75);
      border-bottom: 0.3rem solid rgba(255, 255, 255, 0.75);
      border-left: 0.3rem solid #000;
    }
    &.is-loading {
      -webkit-animation: loader-simple 1.1s infinite linear;
      animation: loader-simple 1.1s infinite linear;
    }
  }
  &__simple,
  &__simple:after {
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }
}

@-webkit-keyframes loader-simple {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes loader-simple {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}