@import "styles/share";

.header {
  position: fixed;
  z-index: 1030;
  transition: background-color 0.2s ease;

  @media (max-width: 768px) {
    .mobile-offcanvas {
      visibility: hidden;
      transform: translateX(100%);
      border-radius: 0;
      overflow-y: scroll;
      overflow-x: hidden;
      transition: visibility 0.1s ease-in-out, transform 0.1s ease-in-out;
      &.show {
        visibility: visible;
        transform: translateX(0);
      }
    }
  }

  :global {
    .screen-overlay {
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      opacity: 0;
      visibility: hidden;
      background-color: rgba(0, 0, 0, 0.64);
      transition: opacity 0.2s linear, visibility 0.1s, width 1s ease-in;

      &.show {
        transition: opacity 0.5s ease, width 0s;
        opacity: 1;
        width: 100%;
        visibility: visible;

        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
    }

    .container-fluid {
      padding: 5px 36px 5px 10px;

      @include media-breakpoint-down(lg) {
        padding: 0;
      }
    }
  }
}

.login-menu {
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: var(--textColor);
  margin: 15px 0;

  &:hover,
  &:focus,
  &:active {
    color: var(--mainColor);
  }

  @include media-breakpoint-down(lg) {
    justify-content: flex-end;
    margin: 10px 5px 0;
  }
}

.top-menu {
  max-width: inherit;
  padding: 0;

  @include media-breakpoint-down(lg) {
    padding: 8px 0;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  }

  :global {
    .mobile {
      @include media-breakpoint-up(md) {
        display: inline-block;
      }

      //Display none when screen size is less than 390px
      @media screen and (max-width: 390px) {
        display: none !important;
      }
    }

    .pc {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    .mobile-login-bar {
      @include media-breakpoint-up(md) {
        display: inline-block;
      }

      font-family: var(--font-roboto), sans-serif;
      color: var(--textColor);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 5px;
      position: relative;

      &--items {
        display: none;
        gap: 5px;
        flex-direction: column;
        position: absolute !important;
        left: -115px !important;
        top: 35px;
        background-color: #fff !important;
        z-index: 0;
        
        span {
          color: #000;
          text-align: center;
          text-transform: uppercase;
          text-shadow: none;
          cursor: pointer;
        }

        &.show {
          display: flex;
        }
      }
    }

    .navbar-collapse {
      @include media-breakpoint-down(lg) {
        background-color: #1b1b1b;
        margin-right: -15px;
        position: absolute;
        top: 0;
        right: 0;
        width: 66%;
        height: 100vh;
        padding: 20px;
      }
      .navbar-nav {
        @include media-breakpoint-down(xl) {
          margin-left: 20px !important;
        }
      }
    }

    .navbar-brand {
      margin: 0;
      margin-left: 38px;
      padding: 0;

      @include media-breakpoint-down(xl) {
        margin-left: 5px;
      }

      @include media-breakpoint-down(lg) {
        margin: 0;
        padding: 0;
      }
    }

    .navbar-toggler {
      margin: 5px 0 5px 5px;

      @include media-breakpoint-down(lg) {
        margin-right: 4px;
      }

      .icon-hamburger-menu {
        font-size: 17px;
      }
    }

    .close-menu {
      display: none;

      @include media-breakpoint-down(lg) {
        margin: 0;
        display: inline-block;
      }
    }

    .login-mobile {
      border: 1px solid var(--textColor);
      border-radius: 4px;
      text-align: center;
      color: var(--textColor);
      display: none;
      padding: 0;
      vertical-align: middle;
      line-height: 1;
      i {
        font-size: 24px;
        line-height: 1;
        width: 24px;
      }

      @include media-breakpoint-down(lg) {
        display: block;
      }
    }

    .navbar-nav .nav-link {
      color: var(--textColor);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 25px;
      text-shadow: 0 3px 5px rgba(0, 0, 0, 0.78);
      font-weight: 500;
      white-space: nowrap;

      @include media-breakpoint-down(md) {
        font-size: 14px;
      }

      @include media-breakpoint-down(lg) {
        color: #fff;
      }

      @media screen and (min-width: 1280px) and (max-width: 1439.98px) {
        margin: 0 12px !important;
      }
    }

    form {
      @include media-breakpoint-down(lg) {
        border-top: 0.5px solid #fff;
        margin-top: 10px;
        flex-direction: column;
        align-items: flex-end !important;
      }

      a {
        color: var(--textColor);
        margin: 0 1em;
        font-size: 16px;

        &:hover,
        &:focus,
        &:active {
          color: var(--mainColor);
        }

        &:first-child {
          @include media-breakpoint-down(lg) {
            margin-top: 15px !important;
          }
        }

        @include media-breakpoint-up(lg) {
          padding: 0.5em 20px;
          font-size: 14px;
          margin: 0;
        }

        @include media-breakpoint-down(lg) {
          display: inline-block;
          margin: 0;
          text-align: right;
        }

        img,
        .fa {
          margin-right: 8px;
        }

        .icon-shop {
          vertical-align: middle;
          margin-right: 10px;
        }

        &.login {
          i {
            width: 24px;
            vertical-align: middle;
            margin-right: 3px;
          }

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .get-started {
      border: none;
      font-family: var(--font-roboto), sans-serif;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      padding: 10px 31px;
      text-transform: uppercase;
      text-shadow: none;

      &:hover {
        background-color: #f9a826;
        color: #fff;
      }

      @include media-breakpoint-down(xl) {
        padding: 6px 12px;
      }

      @include media-breakpoint-down(lg) {
        width: auto;
        display: inline-block;
        font-size: 12px;
        margin-right: 13px;
        padding: 6px 12px;
        font-family: var(--font-roboto), sans-serif;
        font-weight: 500;
        letter-spacing: 0;
      }
    }

    .navbar-nav {
      .nav-item {
        text-align: right;
      }

      .nav-link {
        padding: 4px 0 !important;
        margin: 0 10px;
        border-bottom: 5px solid transparent;

        @include media-breakpoint-up(xl) {
          padding: 7px 0 !important;
        }

        &:hover,
        &:focus {
          color: var(--mainColor);
          border-bottom: 5px solid var(--mainColor);
          text-decoration: none;

          > a {
            color: var(--mainColor);
          }
        }

        > a {
          color: var(--textColor);

          &:hover,
          &:focus {
            color: var(--mainColor);
            text-decoration: none;
          }
        }

        @include media-breakpoint-down(xl) {
          font-size: 12px;
          margin: 0 5px;
        }

        @include media-breakpoint-down(lg) {
          font-size: 14px;
          display: inline-block;
        }
      }

      .active {
        > .nav-link {
          color: var(--textColor);
          letter-spacing: 0;
          border-color: var(--mainColor);

          @include media-breakpoint-down(md) {
            border-bottom: 0;
            color: var(--mainColor);
          }
        }
      }

      .show {
        > .nav-link {
          color: var(--mainColor);
          border-bottom: 5px solid var(--mainColor);
        }
      }
    }

    .dropdown-toggle {
      &:after {
        border: 0;
      }
      &:active,
      &:focus {
        box-shadow: unset !important;
        background-color: transparent !important;
      }

      &.nav-link {
        :hover {
          border-bottom: 0;

          span {
            padding: 8px 0;
            border-bottom: 5px solid var(--mainColor);
            @include media-breakpoint-down(md) {
              border-bottom: 0;
            }
          }
        }

        @include media-breakpoint-down(sm) {
          padding-bottom: 3px;
        }
      }
    }

    .dropdown-menu {
      box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
      margin: 0 !important;
      left: 0;

      &:hover {
        > .dropdown-menu {
          @include media-breakpoint-down(lg) {
            background-color: unset;
            padding-top: 0;
            padding-right: 20px;
          }
        }
      }

      @include media-breakpoint-down(lg) {
        left: 0;
        background-color: transparent;
        border: 0;
        text-align: right;
      }

      &.show {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-down(lg) {
          display: block !important;
          position: relative;

          a:first-child {
            margin-top: 0 !important;
          }
        }
      }

      .dropdown-item {
        padding: 0.25rem 1rem;
        margin: 0.25rem 0;
        border-bottom: 2px solid transparent;
        font-family: var(--font-roboto), sans-serif;
        font-weight: 500;
        color: #000;
        text-wrap: auto;

        &.active,
        &:active,
        &:hover,
        &:focus {
          color: #212121;
          background-color: unset;

          & span {
            border-bottom: 2px solid var(--mainColor);
          }

          @include media-breakpoint-down(lg) {
            color: #ffffff;
          }
        }

        @include media-breakpoint-down(lg) {
          color: #d2d1d1;
          font-family: var(--font-roboto), sans-serif;
          font-weight: 400;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 14px;
          text-transform: capitalize;
          display: block;
          width: auto;
          padding-right: 10px;
        }
      }
    }
  }
}

.logo {
  vertical-align: inherit;

  @include media-breakpoint-down(md) {
    margin-left: 12px;
  }

  @include media-breakpoint-down(lg) {
    width: 140px;
  }

  @media (max-width: 360px) {
    margin-left: 3px;
  }

  @media (max-width: 320px) {
    width: 102px !important;
  }
}
