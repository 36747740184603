@import "styles/share";

.subscribe {
  background-color: #f9a826;
  text-align: center;
  color: var(--textColor);
  display: flex;
  justify-content: center;
  align-items: center;

  :global {
    .container {
      padding-top: 26px;
      padding-bottom: 26px;
    }

    form {
      &.submitted {
        .mdc-text-field__icon {
          color: green;
        }
      }

      .form-group {
        display: inline-block;
        margin: 0;
        flex-flow: row;
        @include media-breakpoint-down(lg) {
          width: 100%;
          padding: 0 15px;
        }
      }
      .btn {
        font-size: 20px;
        margin-right: 1em;
        color: var(--textColor);
        letter-spacing: 0;
        font-weight: 500;
        border: 2px solid var(--textColor);
        border-radius: 0;
        padding: 6px 25px;
        text-transform: uppercase;
        font-family: var(--font-bebas-neue);
        margin-left: 23px;
        &:hover {
          background-color: #F9A826;
          color: #fff;
        }
        &.submitted {
          color: #202020;
          border: 2px solid #202020;
        }
        @include media-breakpoint-down(lg) {
          border-radius: 5px;
          border: 2px solid #fff;
        }
      }
      .mdc-text-field {
        max-height: 45px;
        border-radius: 0;
        @include media-breakpoint-down(lg) {
          max-height: 44px;
        }
        .mdc-floating-label {
          transform: translateY(-5px) !important;
          &--float-above {
            display: none;
          }
        }
        .mdc-text-field__input {
          color: rgba(0, 0, 0, 0.87);
          font-family: var(--font-roboto), sans-serif;
          font-size: 16px;
          letter-spacing: 0.15px;
          line-height: 24px;
          min-width: 270px;
          padding-top: 0;
          padding-bottom: 0;

          @include media-breakpoint-down(lg) {
            max-height: 44px;
          }
        }
        .mdc-text-field__icon {
          bottom: 10px;
        }
      }
    }
  }

  &__title {
    margin-bottom: 16px;
    font-family: var(--font-roboto), sans-serif;
    font-size: 20px;
    margin-right: 1em;
    color: var(--textColor);
    letter-spacing: 0;
    font-weight: 500;
    vertical-align: middle;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      display: inline-flex;
      margin-right: 20px !important;
    }
  }


  &__submit-btn-wrapper {
    display: block;
    margin-top: 10px;

    @include media-breakpoint-up(md) {
      display: inline-flex;
      margin-top: 0;
    }

    :global {
      .loading-spiner {
        left: 50%;
        top: 50%;
        right: auto;
        bottom: auto;
        margin: 0;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__form {
    width: 100%;
    vertical-align: middle;
    display: inline-block;
    margin: 5px 0;

    @include media-breakpoint-up(md) {
      width: auto;
      display: inline-flex;
      align-items: flex-start;
      justify-content: center;
    }
  }
}
