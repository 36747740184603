@import "styles/share";

.footer {
  position: relative;
  z-index: 2;
  background-color: #000;
  padding: 1em;
  font-family: var(--font-roboto), sans-serif;

  :global {
    .card,
    .card .btn-dark {
      background-color: #000;
    }

    .card {
      @include media-breakpoint-down(lg) {
        display: none;
      }

      .btn-dark {
        border: 2px solid var(--textColor);
        font-size: 18px;
        letter-spacing: 0;
        line-height: 23px;
        padding: 10px 20px;
        border-radius: 0;
        font-family: var(--font-bebas-neue);

        &:hover {
          background-color: var(--mainColor);
          color: #fff;
        }
      }
    }
    .card-img-top {
      width: 98px;
      height: 99px;
      margin: 0 auto;
    }
  }

  &__social {
    text-align: center;

    @include media-breakpoint-down(lg) {
      padding: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: 0 auto;
    }

    li {
      :global {
        .fab, .fa, .icon {
          color: var(--textColor);

          @include media-breakpoint-down(lg) {
            font-size: 24px;
          }
        }

        .icon {
          font-size: 28px;
        }
        .fab, .fa {
          font-size: 28px;
        }
      }
      &:hover {
        a {
          color: var(--mainColor);
        }
      }
    }
  }
  &__logo {
    margin-top: 1em;

    @include media-breakpoint-down(lg) {
      width: 181px;
      margin: 0 auto;
    }
  }
  &__menu {
    margin: 1em 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    :global {
      .nav-item {
        .fas, .icon {
          font-size: 16px;
          padding-right: 3px;
        }
      }

      a {
        color: var(--textColor);

        @include media-breakpoint-down(lg) {
          padding: 0 0.35em;
        }

        &:hover {
          color: var(--textColor);
        }
        &:focus {
          color: var(--textColor);
        }
      }
    }
  }
  &__social-list {
    justify-content: center;

    @include media-breakpoint-down(lg) {
      margin: 0;
    }

    :global {
      li {
        &:hover {
          a, .icon, .fab {
            color: var(--mainColor);
          }
        }
      }
    }
  }
}
